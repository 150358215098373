import { createNamespacedHelpers } from 'vuex';
const { mapState } = createNamespacedHelpers('calender');

export const calenderMixin = {
  computed: {
    ...mapState(['listTypes']),
  },

  methods: {
    getTypeName(type = '') {
      return this.listTypes.find((item) => item.value === type.toString())
        ?.text;
    },

    getTextDuration(timeStart, timeEnd) {
      return `${this.getTimeOfDate({
        date: timeStart * 1000,
      })} - ${this.getTimeOfDate({ date: timeEnd * 1000 })}`;
    },

    checkDateIsBeforeToday(date) {
      const checkedDate = this.$moment(date);
      if (!checkedDate.isValid()) return;

      return checkedDate.isBefore(this.$moment());
    },
  },
};
