<template>
  <b-container>
    <b-modal
      id="calendar-schedule-detail-modal"
      title="Thông tin lịch hẹn"
      size="xl"
      centered
      hide-footer
      @show="onShowDetail"
      @hide="resetModal"
    >
      <b-overlay :show="loading">
        <b-container>
          <div class="skd">
            <div class="skd-item">
              <span class="skd-item__title">Tên lịch hẹn</span>
              <div class="skd-item__value">{{ schedule.name }}</div>
            </div>
            <div class="skd-item">
              <span class="skd-item__title">Loại</span>
              <div class="skd-item__value">
                {{ getTypeName(schedule.type) }}
              </div>
            </div>
            <div class="skd-item">
              <span class="skd-item__title">Ngày hẹn</span>
              <div class="skd-item__value">{{ computedDate }}</div>
            </div>
            <div class="skd-item">
              <span class="skd-item__title">Thời gian</span>
              <div class="skd-item__value">{{ computedTime }}</div>
            </div>
            <div class="skd-item">
              <span class="skd-item__title">Người tham gia</span>
              <div class="skd-item__value">{{ computedPatients }}</div>
            </div>
            <div class="skd-item">
              <span class="skd-item__title">Người thực hiện</span>
              <div class="skd-item__value">{{ schedule.updaterName }}</div>
            </div>
            <div class="skd-item">
              <span class="skd-item__title">Mục tiêu</span>
              <div class="skd-item__value">{{ schedule.goal }}</div>
            </div>
            <div class="skd-item">
              <span class="skd-item__title">Link Zoom cho host</span>
              <div class="skd-item__value">{{ schedule.meetingLink }}</div>
            </div>

            <div class="skd-item">
              <span class="skd-item__title">Link Zoom cho KH</span>
              <div class="skd-item__value">
                {{ getLinkJoinText(schedule.linkJoin) }}
                <span
                  v-if="schedule.linkJoin"
                  class="svg-icon"
                  style="cursor: pointer; padding-left: 15px"
                  @click="
                    copyLinkJoin(
                      schedule.linkJoin,
                      schedule.meetingPassword,
                      schedule.roomId,
                    )
                  "
                >
                  <inline-svg src="/media/svg/icons/Neolex/Basic/copy.svg" />
                </span>
              </div>
            </div>

            <div class="skd-item">
              <span class="skd-item__title">Room ID</span>
              <div class="skd-item__value">{{ schedule.roomId }}</div>
            </div>
            <div class="skd-item">
              <span class="skd-item__title">Passcode</span>
              <div class="skd-item__value">{{ schedule.meetingPassword }}</div>
            </div>
            <div class="skd-item">
              <span class="skd-item__title">Link Zoom Web Client</span>
              <div class="skd-item__value">{{ schedule.zoomWebLink }}</div>
            </div>
            <div class="skd-item">
              <span class="skd-item__title">Link Zoom Dynamic Link</span>
              <div class="skd-item__value">
                {{ schedule.dynamicLink }}
                <span
                  class="svg-icon"
                  style="cursor: pointer; padding-left: 15px"
                  @click="copyDynamicLink(schedule.dynamicLink)"
                >
                  <inline-svg src="/media/svg/icons/Neolex/Basic/copy.svg" />
                </span>
              </div>
            </div>
          </div>
        </b-container>

        <div
          v-if="isWritePermission"
          class="pt-6 d-flex justify-content-end align-items-center"
        >
          <b-button
            :disabled="isDisableEdit"
            class="btn mr-2"
            type="button"
            @click="onEditSchedule"
          >
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/edit-2.svg" />
            </span>
            Chỉnh sửa
          </b-button>
          <b-button
            :disabled="isDisableCancel"
            class="btn btn-danger mr-2"
            variant="danger"
            type="button"
            @click="onClickCancel"
          >
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/power.svg" />
            </span>
            Hủy hẹn
          </b-button>
          <b-button
            class="btn btn-success"
            type="button"
            @click="onImplementSchedule"
          >
            Thực hiện
          </b-button>
        </div>
      </b-overlay>
    </b-modal>

    <calendar-schedule-cancel @save="handleCancelSchedule" />
  </b-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import {
  GET_CALENDER_DETAIL,
  CANCEL_CALENDER,
  IMPLEMENT_CALENDER,
} from '@/core/services/store/calender.module';
const { mapActions, mapState } = createNamespacedHelpers('calender');
import { mapGetters } from 'vuex';
import { calenderMixin } from './mixins/CalenderDetail';
import { CALENDAR_REPEAT_TYPE } from '@/core/plugins/constants.js';

export default {
  name: 'CalendarScheduleDetail',

  mixins: [calenderMixin],

  components: {
    CalendarScheduleCancel: () => import('./CalendarScheduleCancel'),
  },

  props: {
    calendarId: {
      type: [String, Number],
    },
  },

  data() {
    return {
      schedule: {},
      loading: false,
    };
  },

  computed: {
    ...mapState(['calendar']),
    ...mapGetters({ profile: 'currentUser' }),

    computedPatients() {
      const patients = this.schedule.calendarAccounts || [];
      return patients.map((item) => item.account.fullName).join(', ');
    },

    computedDate() {
      if (this.schedule.appointmentDate) {
        return this.formatDateLocale(this.schedule.appointmentDate * 1000);
      }
      return '';
    },

    computedTime() {
      if (this.schedule.appointmentDate && this.schedule.duration) {
        return this.getTextDuration(
          this.schedule.appointmentDate,
          this.schedule.appointmentDate + this.schedule.duration,
        );
      }
      return '';
    },

    isApptDateBeforeToday() {
      return this.checkDateIsBeforeToday(this.schedule.appointmentDate * 1000);
    },

    isCreator() {
      return this.schedule.performerId === this.profile.id;
    },

    isDisableEdit() {
      return this.isApptDateBeforeToday || !this.isCreator;
    },

    isDisableCancel() {
      return this.isApptDateBeforeToday || !this.isCreator;
    },

    isWritePermission() {
      return this.$route.meta.isWritePermission;
    },
  },

  watch: {
    calendarId() {
      this.getCalendarDetail();
    },

    calendar(val = {}) {
      if (Object.keys(val).length) {
        this.schedule = { ...val };
        if (this.calendarId) {
          this.schedule.zoomWebLink = `https://zoom.diab.com.vn/login?calendarId=${this.calendarId}`;
        }
      }
    },
  },

  methods: {
    ...mapActions({ GET_CALENDER_DETAIL, IMPLEMENT_CALENDER, CANCEL_CALENDER }),
    getLinkJoinText(linkJoin) {
      return linkJoin ? linkJoin : 'Không có dữ liệu';
    },
    async getCalendarDetail() {
      if (this.calendarId) {
        this.loading = true;
        await this.GET_CALENDER_DETAIL({ id: this.calendarId });
        if (
          this.schedule.dynamicLink === null ||
          this.schedule.dynamicLink === undefined
        ) {
          const longDynamicLink = `https://click.diab.com.vn/referralCode?link=https://diab.com.vn/calendar=${this.calendarId}&ofl=https://zoom.diab.com.vn/login?calendarId=${this.calendarId}&apn=com.vbhc.diab&ibi=com.cactusoftware.diab&isi=1569353448&sd=Để đến với buổi gặp gỡ các bác sĩ và chuyên gia, mời bạn nhấp vào hình ảnh bên dưới để tải ứng dụng zoom giúp kết nối dễ dàng và nhanh chóng.&si=https://9s-booking.s3.ap-southeast-1.amazonaws.com/diab/linkzoom.jpg&st=${this.calendarName}`;
          const dynamicLinks = await this.$api.getFirebaseDynamicLinks({
            longDynamicLink: longDynamicLink,
          });
          this.schedule.dynamicLink = dynamicLinks;
          this.$api
            .put(`Calendar/UpdateDynamicLInk/${this.calendarId}`, {
              DynamicLink: dynamicLinks,
            })
            .then(() => {});
        }
        this.loading = false;
      }
    },

    copyDynamicLink(dynamicLink) {
      navigator.clipboard.writeText(dynamicLink);
    },
    copyLinkJoin(linkJoin, passCode, meetingID) {
      let writeText = `${linkJoin}\nMeeting ID: ${meetingID} Passcode: ${passCode}`;
      navigator.clipboard.writeText(writeText);
    },

    resetModal() {
      this.schedule = {};
    },

    async onImplementSchedule() {
      if (!this.schedule.meetingLink) return;
      let result = await this.$api.get(
        `/Calendar/GetRefreshMeetingLink/${this.calendarId}`,
      );
      if (result.data.meetingLink) {
        console.log(`Expired Meeting Link`);
        window.open(result.data.meetingLink);
      } else {
        window.open(this.schedule.meetingLink);
      }
    },

    onEditSchedule() {
      this.$emit('edit');
    },

    async handleCancelSchedule(option = '') {
      const params = {
        id: this.calendarId,
      };
      if (
        CALENDAR_REPEAT_TYPE.NO_REPEAT !== this.schedule.repeatType.toString()
      ) {
        params.deleteType = option;
      }

      await this.CANCEL_CALENDER(params);
      this.hideDetailModal();
      this.$emit('cancel-schedule');
    },

    onClickCancel() {
      if (
        CALENDAR_REPEAT_TYPE.NO_REPEAT !== this.schedule.repeatType.toString()
      ) {
        this.$bvModal.show('calendar-schedule-cancel-modal');
      } else {
        this.handleCancelSchedule();
      }
    },

    onShowDetail() {
      this.getCalendarDetail();
    },

    hideDetailModal() {
      this.$bvModal.hide('calendar-schedule-detail-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
.skd {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.skd-item {
  display: flex;
  gap: 5%;

  &__title {
    flex: 0 0 20%;
  }

  &__value {
    flex: 0 0 75%;
    word-break: break-word;
  }
}

.btn-danger {
  background-color: #ff5756 !important;
  border-color: #ff5756 !important;
  color: #fff !important;
}
</style>
